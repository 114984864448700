import React from "react";
import { UnderConstruction } from "./components";

function App() {
    return (
        <>
            <UnderConstruction />
        </>
    )
}

export default App;

