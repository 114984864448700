import React, { useState, useEffect } from "react";

export function UnderConstruction() {
    return (
        <>
            <div
            className={'alert alert-dismissible alert-danger'} 
            role="alarm"
            >
                This site is under construction by Barmen Maskin AS
            </div>
        </>
    )
}